/* eslint-disable react/prop-types */
import cl from 'classnames';
import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';

import IconClose from '../shared/CourseLanding/icons/Close';
import IconHamburger from '../shared/Header/IconHamburger';
import { DesktopMenuList } from './DesktopMenuList';
import { Link } from './Link';
import { MobileBottomNav } from './MobileBottomNav';
import { MobileMenuList } from './MobileMenuList';
import { MobileSidebar } from './MobileSideBar';

const HEADER_HEIGHT = 64;
const HAMBURGER_BUTTON_WIDTH = 40;
const LOGO_DOL_WIDTH = 124;
const BTN_ACTION_WIDTH = 124;

const Main = styled.header`
  font-family: 'Inter', sans-serif;
  top: 0;
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  max-width: 100vw;
  min-height: ${HEADER_HEIGHT}px;
  z-index: 104;
  display: grid;
  place-items: center;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);
  padding: 12px 20px;

  .header-wrapper {
    display: grid;
    align-items: center;
    grid-gap: 16px;
    grid-template-columns: max-content ${LOGO_DOL_WIDTH}px minmax(0, 1fr);

    .logo-dol {
      display: block;
      line-height: 0;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .cta-actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 8px;
      .action-link {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
      .btn-action {
        width: auto;
        background-color: ${colorsV2.primary100};
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .btn-round-icon {
      width: ${HAMBURGER_BUTTON_WIDTH}px;
      min-width: ${HAMBURGER_BUTTON_WIDTH}px;
      height: ${HAMBURGER_BUTTON_WIDTH}px;
      min-height: ${HAMBURGER_BUTTON_WIDTH}px;
      background: ${colorsV2.gray10};
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      ${fromScreen(1260)} {
        display: none;
      }
    }

    width: calc(100vw - 32px);
    ${fromScreen(1260)} {
      grid-gap: 24px;
      height: 100%;
      grid-template-columns: ${LOGO_DOL_WIDTH}px minmax(0, 1fr) max-content;
      .cta-actions {
        .action-link {
          margin-right: 16px;
        }
        .btn-action {
          width: ${BTN_ACTION_WIDTH}px;
        }
      }
    }
    ${fromScreen(1366)} {
      width: 1302px;
      margin: 0 auto;
      padding: 0;
    }
  }

  ${fromScreen(1144)} {
    padding: 0;
  }
`;

const Wrapper = styled.div``;

export const CommonHeader = ({
  hideMobileSideBar,
  hideMobileBottomNav,
  menuData = [],
  logoUrl = '/',
  CTAButton = null
}) => {
  const [sidebarOpen, setSideBarOpen] = React.useState(false);

  const mobileOnlyMenu = React.useMemo(
    () => menuData.filter(item => !item.desktopOnly),
    [menuData]
  );
  const desktopOnlyMenu = React.useMemo(
    () => menuData.filter(item => !item.mobileOnly),
    [menuData]
  );

  return (
    <Wrapper className="header-root-wrapper">
      <Main
        className={cl('header-root', {
          'without-sidebar': hideMobileSideBar
        })}
        id="header-root"
      >
        <nav id="navigation" className="header-wrapper" role="navigation">
          <div
            onClick={() => setSideBarOpen(prevVisible => !prevVisible)}
            className="btn-round-icon"
          >
            {sidebarOpen ? <IconClose /> : <IconHamburger />}
          </div>
          <Link className="logo-dol" to={logoUrl}>
            <img
              src="https://media.dolenglish.vn/PUBLIC/MEDIA/Logo_0efdf497dd.png"
              alt="Logo DOL English"
            />
          </Link>

          <DesktopMenuList data={desktopOnlyMenu} />
          {CTAButton}
        </nav>
      </Main>
      {hideMobileSideBar ? null : (
        <MobileSidebar open={sidebarOpen} onVisibleChange={setSideBarOpen}>
          <MobileMenuList data={mobileOnlyMenu} />
        </MobileSidebar>
      )}
      {hideMobileBottomNav ? null : (
        <MobileBottomNav onToggleMenu={() => setSideBarOpen(o => !o)} />
      )}
    </Wrapper>
  );
};
