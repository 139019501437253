import React, { useCallback, useEffect, useRef, useState } from 'react';

import cl from 'classnames';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components/macro';

import { dolCTAReferences } from 'constant';
import { HEADER_HEIGHT } from 'shared/Header';
import { isSafari } from 'utils';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import useMediaQuery from 'utils/react-responsive';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import CalendarIcon from './icons/calendar';
import ChatBubbleIcon from './icons/chat-bubble';
import MedalIcon from './icons/medal';
import PhoneIcon from './icons/phone';
import { shake } from './animations/shaking';

const urlMapper = {
  [dolCTAReferences.REVIEW]: '/hall-of-fame',
  [dolCTAReferences.HALL_OF_FAME]: '/hall-of-fame',
  [dolCTAReferences.SCHEDULE]: '/schedule',
  [dolCTAReferences.REGISTER]: '/register'
};

const NewCTA = () => {
  const [isCollapsed, setCollapse] = useState(true);
  const { isTabletOrMobile } = useMediaQuery();
  const containerRef = useRef();
  const [isRunningOnSafari, setSafari] = useState(false);

  useOnClickOutside(containerRef, () => {
    setCollapse(true);
  });

  useEffect(() => {
    setCollapse(false);
    setSafari(isSafari());

    setTimeout(() => {
      setCollapse(true);
    }, 3000);
  }, []);

  const onItemClick = useCallback(
    e => {
      if (isTabletOrMobile && isCollapsed) {
        setCollapse(false);
        // open CTA then stop
        return;
      }

      const itemName = e.currentTarget.name;
      const ref = window.document.querySelector(`#${itemName}`);
      const promotionElm = document.getElementById('fixed-topbar-scholarship');
      let promotionHeight = 0;
      if (promotionElm) {
        promotionHeight = promotionElm.clientHeight;
      }

      if (ref) {
        window.scrollTo({
          top:
            window.scrollY +
            ref.getBoundingClientRect().top -
            HEADER_HEIGHT -
            promotionHeight,
          behavior: 'smooth'
        });
      } else navigate(urlMapper[itemName]);
    },
    [isCollapsed, isTabletOrMobile]
  );

  return (
    <Container className={cl({ active: !isCollapsed })} ref={containerRef}>
      {/*<Item*/}
      {/*  onClick={onItemClick}*/}
      {/*  name={dolCTAReferences.REVIEW}*/}
      {/*  className={isRunningOnSafari ? 'safari' : ''}*/}
      {/*>*/}
      {/*  <ChatBubbleIcon />*/}
      {/*  <span className="text">Review từ học viên</span>*/}
      {/*</Item>*/}
      {/*<Item*/}
      {/*  onClick={onItemClick}*/}
      {/*  name={dolCTAReferences.HALL_OF_FAME}*/}
      {/*  className={isRunningOnSafari ? 'safari' : ''}*/}
      {/*>*/}
      {/*  <MedalIcon />*/}
      {/*  <span className="text">Bảng vàng thành tích</span>*/}
      {/*</Item>*/}
      {/*<Item*/}
      {/*  onClick={onItemClick}*/}
      {/*  name={dolCTAReferences.SCHEDULE}*/}
      {/*  className={isRunningOnSafari ? 'safari' : ''}*/}
      {/*>*/}
      {/*  <CalendarIcon />*/}
      {/*  <span className="text">Lịch học & Khuyến mãi</span>*/}
      {/*</Item>*/}
      <Item
        onClick={onItemClick}
        name={dolCTAReferences.REGISTER}
        className={isRunningOnSafari ? 'safari' : ''}
      >
        <PhoneIcon className="ringing" />
        <span className="text">Liên hệ DOL</span>
      </Item>
    </Container>
  );
};

const Item = styled.button`
  outline: 0;
  cursor: pointer;
  border: none;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #2d3742;
  width: 216px;
  height: 52px;
  padding: 16px 20px;
  transition: all 300ms;
  text-align: left;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 20px;

  &.safari {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      flex: 0 0 22px;
    }

    .text {
      margin-left: 20px;
    }
  }

  &:hover {
    background-color: #f6f6f6;
  }

  border-radius: 16px 0px 0px 16px;

  // &:first-child {
  //   border-radius: 16px 0px 0px 0px;
  // }
  //
  // &:last-child {
  //   border-radius: 0px 0px 0px 16px;
  // }

  // &:not(:last-child) {
  //   box-shadow: inset 0px -1px 0px #ebecf0;
  // }

  .text {
    display: none;
    white-space: nowrap;
  }

  .ringing {
    display: inline-block;
    animation: ${shake} 1s ease-in-out 2s infinite;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 80px;
  right: 0;
  transform: translateX(156px);
  transition: all 300ms;
  filter: drop-shadow(0px 16px 32px rgba(45, 55, 66, 0.1));
  z-index: 3;

  &:hover,
  &.active {
    transform: translateX(0);

    .text {
      display: inline;
    }
  }

  @media ${deviceBreakpoints.md} {
    top: 126px;
  }
`;

NewCTA.propTypes = {};

NewCTA.defaultProps = {};

export default React.memo(NewCTA);
