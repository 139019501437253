import React from 'react';

const Medal = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33366 12.3667L4.66699 22.1667L7.99574 21.6728L9.7319 24.5556L14.0003 15.6334L9.33366 12.3667Z"
        fill="#DC994B"
      />
      <path
        d="M18.6667 12.3667L23.3333 22.1667L20.0046 21.6728L18.2684 24.5556L14 15.6334L18.6667 12.3667Z"
        fill="#DC994B"
      />
      <path
        d="M21 10.5C21 9.70943 20.6042 9.01669 20.0014 8.59757C19.6763 8.3715 19.5189 7.99134 19.5889 7.60158C19.7188 6.879 19.5088 6.10925 18.9498 5.55024C18.3907 4.99122 17.621 4.78121 16.8984 4.91107C16.5087 4.98111 16.1285 4.82365 15.9024 4.49853C15.4833 3.89579 14.7905 3.5 14 3.5C13.2095 3.5 12.5167 3.89579 12.0976 4.49853C11.8715 4.82365 11.4913 4.98112 11.1016 4.91107C10.379 4.78121 9.60925 4.99122 9.05024 5.55024C8.49123 6.10925 8.28124 6.879 8.4111 7.60158C8.48114 7.99134 8.32369 8.3715 7.99855 8.59757C7.39579 9.01669 7 9.70943 7 10.5C7 11.2906 7.39579 11.9833 7.99855 12.4024C8.32368 12.6285 8.48114 13.0087 8.4111 13.3984C8.28124 14.121 8.49123 14.8907 9.05024 15.4497C9.60925 16.0087 10.379 16.2187 11.1016 16.0889C11.4913 16.0188 11.8715 16.1763 12.0976 16.5014C12.5167 17.1042 13.2094 17.5 14 17.5C14.7906 17.5 15.4833 17.1042 15.9024 16.5014C16.1285 16.1763 16.5087 16.0188 16.8984 16.0889C17.621 16.2187 18.3907 16.0087 18.9498 15.4497C19.5088 14.8907 19.7188 14.121 19.5889 13.3984C19.5189 13.0087 19.6763 12.6285 20.0015 12.4024C20.6042 11.9833 21 11.2906 21 10.5Z"
        fill="#CD483D"
      />
      <path
        d="M14.0001 13.7666C15.8042 13.7666 17.2667 12.3041 17.2667 10.4999C17.2667 8.69581 15.8042 7.23328 14.0001 7.23328C12.1959 7.23328 10.7334 8.69581 10.7334 10.4999C10.7334 12.3041 12.1959 13.7666 14.0001 13.7666Z"
        fill="white"
      />
    </svg>
  );
};

Medal.propTypes = {};

Medal.defaultProps = {};

export default React.memo(Medal);
