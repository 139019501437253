import React from 'react';
import PropTypes from 'prop-types';

const IconEssentialClose = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.227 4.81a1 1 0 00-1.415 1.415l5.775 5.774-5.775 5.775a1 1 0 101.415 1.414L12 13.414l5.775 5.774a1 1 0 001.414-1.414L13.416 12l5.774-5.774a1 1 0 10-1.414-1.415l-5.775 5.775L6.227 4.81z"
      clipRule="evenodd"
    />
  </svg>
);

IconEssentialClose.propTypes = {
  className: PropTypes.string
};

IconEssentialClose.defaultProps = {
  className: ''
};

export default IconEssentialClose;
