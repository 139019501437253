import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { shadows } from 'style/shadows';
import { shaking } from 'shared/DolBigCTA/animations/shaking';
import { Link } from 'gatsby';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import MessengerIcon from 'components/Icon/SVGIcons/MessengerIcon';
import IconEssentialClose from 'components/Icon/SVGIcons/IconEssentialClose';
import { v4 as uuidv4 } from 'uuid';

import { UserPixelTracking } from 'utils/userPixelTracking';

const ZALO_CHAT_WIDGET_WIDTH = 350;
const ZALO_CHAT_WIDGET_HEIGHT = 200;
const ModalContent = styled.div`
  position: relative;

  width: 243px;
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  background-color: ${colorsV2.white100};
  box-shadow: ${shadows.topZIndex3};
  z-index: 2;

  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${colorsV2.white100};
    border: 1px solid ${colorsV2.gray20};
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
    right: 20px;
    bottom: -9px;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 99999;

  display: grid;
  justify-items: end;
  gap: 16px;
  ${shaking}

  .zalo-chat-widget {
    bottom: 130px !important;
    right: 30px !important;
    top: auto !important;
    left: auto !important;
  }
`;

const Header = styled.div`
  padding: 4px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: end;
`;
const Body = styled.div`
  padding: 0px 12px 12px;
  border-radius: 0 0 8px 8px;
`;

const InboxWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 40px;
  background-color: ${colorsV2.primary100};

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${colorsV2.white100};
    font-size: 28px;
  }
`;

const CloseWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${colorsV2.black40};
    font-size: 16px;
  }
  &:hover {
    background-color: ${colorsV2.gray10};
  }
`;

export const ContactDol = () => {
  // const [showModal, setShowModal] = React.useState(true);
  const onClick = () => {
    const uid = uuidv4();
    UserPixelTracking.clickEventPixelSDK(uid);
    UserPixelTracking.clickEvent(uid);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.ZaloSocialSDK) {
        setTimeout(() => {
          window.ZaloSocialSDK.reload();
        }, 1000);
      }

      window.addEventListener('message', event => {
        if (event?.data?.cmd === 'zchat_widget_toggle_sticker') {
          if (
            event?.data?.width === ZALO_CHAT_WIDGET_WIDTH &&
            event?.data?.height === ZALO_CHAT_WIDGET_HEIGHT
          ) {
            onClick();
          }
        }
      });
    }
  });

  return (
    <Container>
      <div
        tabIndex={1}
        className="zalo-chat-widget"
        data-oaid="3524453634164038405"
        data-welcome-message="Rất vui khi được hỗ trợ bạn!"
        data-autopopup="0"
        data-width={`${ZALO_CHAT_WIDGET_WIDTH}`}
        data-height={`${ZALO_CHAT_WIDGET_HEIGHT}`}
      />
      <Link
        to="https://m.me/dolenglish.ieltsdinhluc"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InboxWrapper onClick={onClick} className="shaking">
          <MessengerIcon />
        </InboxWrapper>
      </Link>
    </Container>
  );
};
