import React from 'react';

const ChatBubble = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0333 0.083252H1.43333C0.917867 0.083252 0.5 0.501119 0.5 1.01659V14.0833C0.5 14.5987 0.917867 15.0166 1.43333 15.0166H7.27578L10.8687 19.5083C10.9576 19.619 11.0916 19.6833 11.2333 19.6833C11.3751 19.6833 11.509 19.619 11.5979 19.5083L15.1909 15.0166H21.0333C21.5488 15.0166 21.9667 14.5987 21.9667 14.0833V1.01659C21.9667 0.501119 21.5488 0.083252 21.0333 0.083252Z"
        fill="#F06637"
      />
      <path
        d="M15.8996 6.14989H6.56628C6.30834 6.14989 6.09961 5.94116 6.09961 5.68322C6.09961 5.42528 6.30834 5.21655 6.56628 5.21655H15.8996C16.1575 5.21655 16.3663 5.42528 16.3663 5.68322C16.3663 5.94116 16.1575 6.14989 15.8996 6.14989Z"
        fill="white"
      />
      <path
        d="M12.1663 9.88329H6.56628C6.30834 9.88329 6.09961 9.67456 6.09961 9.41662C6.09961 9.15868 6.30834 8.94995 6.56628 8.94995H12.1663C12.4242 8.94995 12.6329 9.15868 12.6329 9.41662C12.6329 9.67456 12.4242 9.88329 12.1663 9.88329Z"
        fill="white"
      />
    </svg>
  );
};

ChatBubble.propTypes = {};

ChatBubble.defaultProps = {};

export default React.memo(ChatBubble);
