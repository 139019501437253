import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.733398 18.9334C0.733398 19.1809 0.831731 19.4183 1.00677 19.5934C1.1818 19.7684 1.4192 19.8667 1.66673 19.8667H19.8667C20.238 19.8667 20.5941 19.7192 20.8567 19.4567C21.1192 19.1941 21.2667 18.838 21.2667 18.4667V6.80005H0.733398V18.9334Z"
        fill="#DC994B"
      />
      <path
        d="M20.3334 2.13342H1.66673C1.4192 2.13342 1.1818 2.23176 1.00677 2.40679C0.831731 2.58182 0.733398 2.81922 0.733398 3.06676V6.80009H21.2667V3.06676C21.2667 2.81922 21.1684 2.58182 20.9934 2.40679C20.8183 2.23176 20.5809 2.13342 20.3334 2.13342Z"
        fill="#CD483D"
      />
      <path
        d="M4.4668 4.46672V0.73339C4.4668 0.609623 4.51596 0.490924 4.60348 0.403407C4.691 0.31589 4.8097 0.266724 4.93346 0.266724H5.8668C5.99056 0.266724 6.10926 0.31589 6.19678 0.403407C6.2843 0.490924 6.33346 0.609623 6.33346 0.73339V4.46672C6.33346 4.59049 6.2843 4.70919 6.19678 4.79671C6.10926 4.88422 5.99056 4.93339 5.8668 4.93339H4.93346C4.8097 4.93339 4.691 4.88422 4.60348 4.79671C4.51596 4.70919 4.4668 4.59049 4.4668 4.46672Z"
        fill="#444444"
      />
      <path
        d="M15.667 4.46672V0.73339C15.667 0.609623 15.7162 0.490924 15.8037 0.403407C15.8912 0.31589 16.0099 0.266724 16.1337 0.266724H17.067C17.1908 0.266724 17.3095 0.31589 17.397 0.403407C17.4845 0.490924 17.5337 0.609623 17.5337 0.73339V4.46672C17.5337 4.59049 17.4845 4.70919 17.397 4.79671C17.3095 4.88422 17.1908 4.93339 17.067 4.93339H16.1337C16.0099 4.93339 15.8912 4.88422 15.8037 4.79671C15.7162 4.70919 15.667 4.59049 15.667 4.46672Z"
        fill="#444444"
      />
      <path
        d="M12.4004 17.0667C12.4004 17.9897 12.6741 18.8919 13.1869 19.6594C13.6996 20.4268 14.4285 21.0249 15.2812 21.3781C16.1339 21.7313 17.0722 21.8238 17.9775 21.6437C18.8827 21.4636 19.7142 21.0192 20.3669 20.3665C21.0195 19.7139 21.464 18.8824 21.6441 17.9771C21.8241 17.0719 21.7317 16.1336 21.3785 15.2808C21.0253 14.4281 20.4271 13.6993 19.6597 13.1865C18.8923 12.6737 17.99 12.4 17.0671 12.4C15.8298 12.4014 14.6436 12.8935 13.7687 13.7684C12.8939 14.6432 12.4017 15.8294 12.4004 17.0667Z"
        fill="#CD483D"
      />
      <path
        d="M19.73 15.3368C19.8175 15.4243 19.8666 15.543 19.8666 15.6668C19.8666 15.7905 19.8175 15.9092 19.73 15.9967L16.4633 19.2634C16.3758 19.3508 16.2571 19.4 16.1334 19.4C16.0097 19.4 15.891 19.3508 15.8035 19.2634L14.4035 17.8634C14.3185 17.7753 14.2714 17.6575 14.2725 17.5351C14.2735 17.4127 14.3226 17.2957 14.4091 17.2092C14.4957 17.1227 14.6127 17.0736 14.7351 17.0725C14.8574 17.0714 14.9753 17.1185 15.0633 17.2035L16.1334 18.2736L19.0701 15.3368C19.1576 15.2493 19.2763 15.2002 19.4001 15.2002C19.5238 15.2002 19.6425 15.2493 19.73 15.3368Z"
        fill="white"
      />
    </svg>
  );
};

Calendar.propTypes = {};

Calendar.defaultProps = {};

export default React.memo(Calendar);
