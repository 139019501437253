/* eslint-disable react/prop-types */
import { sortBy } from 'lodash';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { colorsV2 } from '../../style/colors-v2';
import ContactIcon from './icons/ContactIcon';
import GoogleMapIcon from './icons/GoogleMapIcon';
import MessengerIcon from './icons/MessengerIcon';
import PhoneIcon from './icons/PhoneIcon';

const jelloVertical = keyframes`
/* ----------------------------------------------
 * Generated by Animista on 2020-8-13 14:53:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }

`;

const InfoLink = styled.a`
  display: grid;
  cursor: pointer;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  align-items: center;
  transition: all 300ms;
  border-radius: 8px;
  min-height: 40px;
  text-decoration: none;
  color: #2d3742;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    background-color: #f4f5f7;

    .icon {
      animation: ${jelloVertical} 700ms 300ms;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .icon {
    grid-row: 1 / 3;
    background: #f4f5f7;
    border-radius: 12px;
    padding: 10px;
    line-height: 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const InfoTitle = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / span ${p => p.$span};

  span {
    color: ${colorsV2.black100};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const InfoDescription = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${colorsV2.black80};
`;

const iconMapper = {
  phone: <PhoneIcon />,
  messenger: <MessengerIcon />,
  address: <GoogleMapIcon />,
  contactForm: <ContactIcon />
};

export const DolContact = ({
  Heading: ContactHeading,
  overrideDolInfo,
  onInfoClick,
  dolInfo
}) => {
  const data = sortBy(
    Object.entries(Object.assign({}, dolInfo, overrideDolInfo)),
    arrays => arrays[1].order
  );

  return (
    <>
      {ContactHeading && <ContactHeading />}
      {data.map(([key, info]) => {
        return (
          <Info
            key={key}
            keyName={key}
            info={info}
            onClick={() => onInfoClick(key, info)}
          />
        );
      })}
    </>
  );
};

const Info = ({ onClick, info, keyName, ...props }) => {
  return (
    <InfoLink
      className={[keyName]}
      onClick={null}
      href={info.href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <div className="icon">{iconMapper[keyName]}</div>
      <InfoTitle
        $span={info.description ? 1 : 2}
        dangerouslySetInnerHTML={{ __html: info.title }}
      />
      {info.description ? (
        <InfoDescription>{info.description}</InfoDescription>
      ) : null}
    </InfoLink>
  );
};
